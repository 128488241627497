import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/layout"
import Seo from "../components/global/Seo/seo"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import CmsContent from "./../components/CmsContent/CmsContent"

export const whyItMattersPage = graphql`
  query whyItMattersPage {
    prismicCvswapoutWhyItMatters {
      data {
        name
        seo {
          document {
            ...seoData
          }
        }
        hero_banner {
          document {
            ...heroBannerData
          }
        }
        body {
          ... on PrismicCvswapoutWhyItMattersDataBodyRichtext {
            id
            slice_type
            primary {
              content {
                richText
                html
              }
            }
          }
        }
      }
    }
  }
`

const WhyItMattersPage = ({ data }) => {
  console.log('data', data);
  return (
    <Layout>
      <Seo data={data.prismicCvswapoutWhyItMatters.data.seo.document.data} />
      <HeroBanner
        data={data.prismicCvswapoutWhyItMatters.data.hero_banner.document.data}
      />
      <article className="why-it-matters static-page-width">
        <section className="container article-body">
          <div className={`col-sm-12 col-md-8 offset-md-2`}>
            <CmsContent article={data.prismicCvswapoutWhyItMatters.data.body} />
          </div>
        </section>
      </article>
    </Layout>
  )
}
export default WhyItMattersPage
